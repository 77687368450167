// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.teammate {
  display: flex;

  &:not(.isSecond) {
    border-radius: 7px;
    background: $c_white;
    border: 1px solid $c_stroke;
    margin-bottom: 10px;
  }
}

.indicator {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  padding-top: 10px;
  padding-right: 10px;

  @include media-breakpoint-down(lg) {
    padding-top: 22px;
  }

  &.isFirstLevel {
    padding-top: 24px;
    padding-right: 0;
    width: 50px;

    @include media-breakpoint-down(lg) {
      padding-top: 18px;
    }
  }
}

.square {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  font-size: 30px;
  cursor: pointer;
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.08);
  }

  &.isDisabled {
    cursor: default;
  }
}

.squareIcon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 1em;
  transition-property: opacity, transform, color;
  transition-duration: 300ms;
  transition-timing-function: ease;
  color: $c_accent;

  .indicator.isExpanded & {
    transform: rotate(180deg);
    color: $c_darkgray;
  }

  .square.isDisabled & {
    color: rgba(239, 241, 243, 1);
  }

  &.isPlus {
    opacity: 1;

    .indicator.isExpanded & {
      opacity: 0;
    }
  }

  &.isMinus {
    opacity: 0;

    .indicator.isExpanded & {
      opacity: 1;
    }
  }
}

.lineH {
  height: 10px;
  margin-left: auto;
  width: calc(100% + 10px);
  margin-right: -10px;
  border-bottom: 1px solid $c_stroke;

  transition-property: width, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease;

  @include media-breakpoint-down(lg) {
    border-bottom-color: transparent;
  }

  .indicator.isFirstLevel & {
    height: 6px;
    border-bottom-color: transparent;
  }

  .indicator.isExpanded & {
    width: 0;
    transition: width 300ms ease;
  }
}

.lineV {
  height: calc(100% - 50px);
  width: 1px;
  background-color: $c_stroke;

  .indicator.isFirstLevel & {
    transition-property: height;
    transition-duration: 300ms;
    transition-timing-function: ease;

    height: calc(100% - 54px);
  }

  .indicator.isExpanded.isFirstLevel & {
    height: calc(100% - 46px);
  }
}

.group {
  display: block;
  flex-grow: 1;

  &.withMB {
    margin-bottom: -1px;
  }
}

.header {
  flex-grow: 1;
  display: flex;
  border-bottom: 1px solid $c_stroke;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.caption {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 9.5px 10px 9.5px 0;
}

.avatar {
  display: flex;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  font-size: 30px;
  border: 1px solid $c_stroke;

  @include media-breakpoint-down(lg) {
    height: 40px;
    width: 40px;
  }

  .isFirstLevel & {
    font-size: 59px;
  }
}

.title {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;

  .isFirstLevel & {
    font-size: 16px;
    line-height: 20px;
  }
}

.level {
  margin-left: 12px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  background: $c_stroke;
}

.status {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $c_darkgray;

  @include media-breakpoint-down(lg) {
    margin-right: 10px;
  }

  &:after {
    @include media-breakpoint-down(lg) {
      content: '•';
      margin-left: 10px;
    }
  }
}

.treeLevels {
  font-size: 12px;
  line-height: 20px;
  margin-left: auto;
  min-width: 120px;
  text-transform: uppercase;
  color: $c_darkgray;

  @include media-breakpoint-down(xl) {
    min-width: 80px;
  }
  @include media-breakpoint-down(lg) {
    min-width: auto;
    text-align: left;
    margin-left: 0;
  }
}

.info {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    padding-top: 4px;
  }
}

.right {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    justify-content: space-between;
    padding-bottom: 10px;
  }
}

.socials {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-left: 1px solid $c_stroke;
  padding-left: 19.5px;
  padding-right: 19.5px;
}

.soc {
  display: flex;

  color: #e7e7e7;

  .isTelegram {
    color: getSocColor('telegram');
  }
  .isViber {
    color: getSocColor('viber');
  }
  .isWhatsapp {
    color: getSocColor('whatsapp');
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.socIcon {
  font-size: 30px;
}

.tools {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  border-left: 1px solid $c_stroke;

  @include media-breakpoint-down(lg) {
    padding-left: 0;
    padding-right: 10px;
    border: none;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: $c_darkgray;

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.icon {
  font-size: 20px;
}

.section {
  overflow: hidden;
}
