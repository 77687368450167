// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 1.25rem;
  line-height: 2;
  font-weight: 500;
  padding: 1.1875rem 1.25rem;
  text-align: center;
  border-bottom: 1px solid $c_stroke;
}
