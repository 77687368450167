// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.nav {
  margin-top: $toolsSize;
  width: 220px;
  border-right: 1px solid $c_stroke;
  background-color: $c_white;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  overflow: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 10px;

  &.isSelected {
    color: $c_accent;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c_blueLight;
  border-radius: 7px;
}

.icon {
  position: relative;
  font-size: 1.25rem;
}

.title {
  position: relative;
  margin-left: 10px;
}
