// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.progressBarAndArrow {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}
.progressBar {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;

  @include media-breakpoint-down(xl) {
    padding: 0 10px;
    width: auto;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
    width: auto;
  }
}
.arrowBtn {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  padding-bottom: 8px;
  display: inline-flex;
}

.arrow {
  font-size: 20px;
  color: $c_darkgray;
  transform: rotateX(180deg);
}

.progressBarField {
  margin: 0;
  position: relative;
  width: 60px;
  height: 6px;
  background-color: $c_stroke;
  border-radius: 3px;
}

.progressIndicator {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 10px;
  height: 6px;
  border-radius: 3px;
}

.progressStatus {
  margin: 0;
  color: $c_darkgray;
  text-transform: uppercase;
  font-size: 0.5rem;
}

.dropDownStatus {
  position: relative;
  padding-left: 10px;
  padding-top: 7px;

  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}

.dropDownContent {
  padding: 5px 12px;
  position: absolute;
  left: -60px;
  background: $c_darkgray;
  color: $c_stroke;
  border: 1px solid $c_stroke;
  border-radius: 7px;
  z-index: 2;
}

.item {
  margin: 0;
}

.hidden {
  visibility: hidden;
}
