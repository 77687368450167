// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.control {
  position: relative;

  &:not(:last-child) {
    margin-right: 66px;

    @include media-breakpoint-down(xl) {
      margin-right: 20px;
    }

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-grow: 1;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 0;
  opacity: 0;
  padding: 0;
  visibility: hidden;
}

.prefix {
  color: $c_darkgray;
  margin-right: 5px;
}

.txt {
  @include media-breakpoint-down(md) {
    margin-left: auto;
    color: $c_dark;
  }
}

.prefixIcon {
  font-size: 14px;
  margin-right: 5px;
  margin-top: -2px;
  color: $c_darkgray;
}

.arrow {
  color: $c_darkgray;
  font-size: 20px;
  transform: scaleY(-1);
  margin-left: 1px;
}

.body {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  background-color: #fff;
  padding: 10px 0;
  z-index: zIndex('dropdown');
}

.action {
  display: block;
  padding: 4px 5px;
  cursor: pointer;

  &.isSelected {
    color: $c_accent;
  }

  &:hover {
    background-color: $c_blueLight;
  }
}
