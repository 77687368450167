// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.header {
  position: fixed;
  top: 0;
  left: $toolsSize;
  right: 0;
  display: flex;
  height: $toolsSize;
  padding: 0 0 0 1.25rem;
  padding-right: var(--removed-body-scroll-bar-size, 0);
  border-bottom: 1px solid $c_stroke;
  background-color: $c_white;
  z-index: 101;

  @include media-breakpoint-down(lg) {
    padding: 0;
    top: 60px;
    left: 0;
  }
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.logoBrand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $c_dark;
  border: 1px solid $c_stroke;
  color: $c_stroke;
  border-radius: 8px;
}

.logoBrandIcon {
  font-size: 24px;
}

.logoDescription {
  font-size: 1rem;
  line-height: normal;
  margin-left: 0.625rem;
  max-width: 155px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar {
  font-size: 40px;
  border-radius: 8px;
}

.navigation {
  display: flex;
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    flex-grow: 1;
    margin-left: 0;
  }
}

.button {
  background: none;
  border: none;
  color: $c_accent;
}

.link {
  padding: 15px 30px;
  border-right: 1px solid $c_stroke;
  text-decoration: none !important;

  @include media-breakpoint-down(lg) {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
  }

  &:hover {
    background-color: $c_bg;
  }

  &:first-child {
    border-left: 1px solid $c_stroke;

    @include media-breakpoint-down(lg) {
      border-left: none;
    }
  }

  &:last-child {
    @include media-breakpoint-down(lg) {
      border-right: none;
    }
  }
}

.linkIcon {
  font-size: 1.25rem;

  @include media-breakpoint-down(lg) {
    flex-shrink: 0;
  }
}

.linkDesc {
  font-size: 0.875rem;
  line-height: normal;
  margin-left: 5px;

  @include media-breakpoint-down(md) {
    margin-left: 10px;
  }
}

.dropdown {
  display: flex;
}
