html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  line-height: 1.875;
  background-color: $c_bg;
  color: $c_dark;
  overflow-y: scroll;
}

#root {
  display: block;
  min-height: 100vh;
  overflow-x: hidden;
}
