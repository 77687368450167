// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-bottom: 20px;
  margin-top: -10px;
}

.picture {
  width: 110px;
}

.caption {
  text-align: center;
  color: $c_darkgray;
  line-height: normal;
}
