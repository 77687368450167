// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.lists {
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}
