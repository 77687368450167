// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.split {
  margin-top: 20px;
  margin-bottom: 41px;
  width: 100%;
  border: none;
  border-bottom: 1px solid $c_stroke;
}
