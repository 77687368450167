// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.item {
  display: flex;
  align-items: center;
  margin-left: 70px;
  margin-bottom: 11px;
  color: $c_dark;

  &:last-child {
    margin-bottom: 0;
  }
}

.file {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
