// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.item {
  display: flex;
  width: 100%;
  margin-bottom: 1.875rem;

  &:last-child {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(xxl) {
    flex-direction: column;
  }
}

.itemTitle {
  min-width: 250px;
  font-size: 0.875rem;
  color: $c_darkgray;
  line-height: 1.25rem;
}

.itemBoxes {
  padding-left: 21px;
  display: flex;
  max-width: 460px;
  width: 100%;
  gap: 86px;

  @include media-breakpoint-down(xxl) {
    padding-left: 0;
    padding-top: 20px;
  }

  @include media-breakpoint-down(sm) {
    gap: 40px;
  }
}

.label {
  font-size: 1rem;
}
