@import '../../../src/styles/abstracts/index.scss';

:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#292d32, 0.5);
  z-index: 1001;

  @include media-breakpoint-down(lg) {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
