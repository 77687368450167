// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 120px);

  @include media-breakpoint-down(lg) {
    max-height: 100%;
    overflow: hidden;
  }
}

.inner {
  padding: 30px;
  min-height: 100%;

  @include media-breakpoint-down(lg) {
    max-width: 660px;
    padding: 20px;
    margin: 0 auto;
  }
}

.paddingThin {
  padding: 20px 30px;

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }
}
