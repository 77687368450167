// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.content {
  display: flex;
  align-items: center;
  background-color: $c_white;
  height: 60px;
  border: 1px solid $c_stroke;
  border-radius: 7px;
}

.icon {
  width: 69px;
  color: $c_orange;
  font-size: 30px;

  @include media-breakpoint-down(sm) {
    width: 50px;
  }
}

.info {
  padding: 0 19px;
  display: flex;
  align-items: center;
  border-left: 1px solid $c_stroke;
  height: inherit;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }
}

.desc {
  font-size: 1rem;

  @include media-breakpoint-down(lg) {
    text-align: center;
    line-height: normal;
    max-width: 300px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    max-width: 260px;
  }
}

.imageBox {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(lg) {
    height: calc(100vh - 400px);
    min-height: 300px;
    margin: 20px 0;
  }
}

.img {
  @include media-breakpoint-down(lg) {
    height: 100%;
  }
}
