// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.listCard {
  height: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 0.625rem;
  border: 1px solid $c_stroke;
  border-radius: 7px;

  @include media-breakpoint-down(xl) {
    height: auto;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: initial;
    height: 140px;
    padding: 18px 10px 10px;
  }
}

.head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 6px;
}

.group {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutCustomer {
  padding: 0 17px 0 19px;
  height: inherit;
  display: flex;
  flex-grow: 3;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid $c_stroke;

  @include media-breakpoint-down(xl) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(lg) {
    border-left: none;
    flex-grow: inherit;
    padding: 0;
  }
}

.customerName {
  margin: 0;
  margin-right: 1rem;
  font-size: 1rem;
  flex-shrink: 4;
  line-height: normal;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 18px;
    line-height: 30px;
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.aboutCustomerRight {
  display: flex;
  gap: 20px;
  flex-shrink: 0;

  @include media-breakpoint-down(xl) {
    flex-direction: column-reverse;
    gap: 5px;
    padding: 10px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.kindOfTask {
  display: flex;
  padding: 0.5rem 5px;
  height: 30px;
  gap: 0.625rem;
  align-items: center;
  background-color: #f1f4ff;
  border-radius: 2px;
}

.iconSource {
  color: #7286bf;
  font-size: 14px;
}

.desc {
  font-size: 13px;
  margin: 0;
  color: #7286bf;
}

.date {
  margin: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 30px;
  background-color: $c_stroke;
  border-radius: 2px;
  color: $c_darkgray;
  font-size: 13px;
  font-weight: 500;

  @include media-breakpoint-down(xl) {
    height: auto;
    background-color: transparent;
    justify-content: flex-end;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  .dateText {
    margin: 0;
  }
}

.tools {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $c_stroke;
  min-width: 59px;

  @include media-breakpoint-down(xl) {
    min-width: 50px;
  }

  @include media-breakpoint-down(lg) {
    min-width: auto;
    margin-left: 13px;
    border-left: none;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: $c_darkgray;

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  .icon {
    font-size: 1.3rem;
  }
}
