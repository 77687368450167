// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.courseDate {
  display: flex;
  align-items: center;
  height: 30px;
  width: 90px;
  background-color: $c_stroke;
  padding: 0px 8px;
  border-radius: 2px;
  color: $c_darkgray;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  white-space: nowrap;
}
