// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.content {
  padding-top: $toolsSize + 30px;
  padding-left: 280px + 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  @include media-breakpoint-down(lg) {
    padding: 160px 10px 88px;
    max-width: 640px;
    margin: 0 auto;
  }
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}
