// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.support {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.row {
  display: flex;
  flex-grow: 1;

  &:not(:last-child) {
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid $c_stroke;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.type {
  width: 340px;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.link {
  padding-left: 20px;
  flex-grow: 1;
  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
}
