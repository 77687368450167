//
// Base
//
// The font size set on the root html element.
$base-font-size: 16px !default;
$base-line-height: 20px !default;
$base-unit: 'em' !default;
$base-font-family: 'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif !default;

// Titles
//
// The font sizes for h1-h6.

// $h1-font-size: 2    * $base-font-size !default;
// $h2-font-size: 1.5  * $base-font-size !default;
// $h3-font-size: 1.17 * $base-font-size !default;
// $h4-font-size: 1    * $base-font-size !default;
// $h5-font-size: 0.83 * $base-font-size !default;
// $h6-font-size: 0.67 * $base-font-size !default;

// Color system
//
// All colors that used in theme.

$c_white: #ffffff !default;
$c_stroke: #eff1f3 !default;
$c_dark: #292d32 !default;
$c_accent: #1d63cb !default;
$c_second: #ccdcf2 !default;
$c_toryBlue: #144fa4 !default;
$c_darkgray: #8893a2 !default;
$c_perano: #b0ccf5 !default;
$c_bg: #f8fafb !default;
$c_blueLight: #e8f0fb !default;
$c_orange: #fabe66 !default;
$c_green: #2cb742 !default;
$c_lightgreen: #d6fedc !default;
$c_red: #ff6161 !default;

$socials-colors: (
  telegram: #039be5,
  viber: #665cac,
  whatsapp: #2cb742
) !default;

$b-normal: cubic-bezier(0.4, 0, 0.2, 1);

// Body
//
// Settings for the `<body>` element.

$body-bg: $c_bg !default;
$body-color: $c_dark !default;
$link-color: $c_accent !default;

// Grid breakpoints

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$br_xs: 0 !default;
$br_sm: 576px !default;
$br_md: 768px !default;
$br_lg: 992px !default;
$br_xl: 1200px !default;
$br_xxl: 1360px !default;
$br_xxxl: 1400px !default;

$grid-breakpoints: (
  xs: $br_xs,
  sm: $br_sm,
  md: $br_md,
  lg: $br_lg,
  xl: $br_xl,
  xxl: $br_xxl,
  xxxl: $br_xxxl
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Dashboard
$toolsSize: 60px;

// Theme z-index strategy variables
$theme-index: (
  overlay: 1,
  overlapping: 2,
  dropdown: 3,
  fixed: 102,
  modal: 1001
) !default;

:export {
  // Colors
  c_white: $c_white;
  c_stroke: $c_stroke;
  c_dark: $c_dark;
  c_accent: $c_accent;
  c_darkgray: $c_darkgray;
  c_bg: $c_bg;
  c_orange: $c_orange;
  c_green: $c_green;
  c_lightgreen: $c_lightgreen;
  c_red: $c_red;

  br_xs: $br_xs;
  br_sm: $br_sm;
  br_md: $br_md;
  br_lg: $br_lg;
  br_xl: $br_xl;
  br_xxl: $br_xxl;
  br_xxxl: $br_xxxl;
}
