// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.plate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px - 1px;
  border-radius: 7px;
  border: 1px solid $c_stroke;
  background: $c_white;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    justify-content: center;
  }
}

.avatar {
  position: relative;
  margin-top: 10px;
  padding: 10px;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 110px;
  color: $c_accent;
}

.view {
  position: relative;
  font-size: 90px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(lg) {
    align-items: flex-start;
    margin-left: 20px;
  }
}

.title {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 500;
  color: $c_darkgray;
}

.description {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.divider {
  margin: 0 5px;
}
