// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.root {
  display: flex;
  align-items: center;
}

.square {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  font-size: 1.875rem;
  background: $c_bg;
  border: 1px solid $c_darkgray;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 200ms ease;

  &.hasError {
    border-color: $c_red;
  }

  &.isChecked {
    background-color: $c_white;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

.icon {
  color: $c_stroke;
  font-size: 1.25rem;
  transition: color 200ms ease;
  .isChecked & {
    color: $c_green;
  }
}

.description {
  margin-left: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
}
