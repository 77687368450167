// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  @include media-breakpoint-down(lg) {
    margin: 0;
  }
}

.col {
  width: 33.333333%;
  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-down(xxl) {
    width: 50%;
    flex-grow: 1;
  }
}

.group:not(:last-child) {
  margin-bottom: 10px;
}

.group {
  @include media-breakpoint-down(xxl) {
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}
