// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.plate {
  border-radius: 7px;
  border: 1px solid $c_stroke;
  background: $c_white;
}

.header {
  display: flex;
  align-items: center;
  padding: 19px 19px 20px;
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.reference {
  display: flex;
  margin-left: 10px;
}

.referenceIcon {
  font-size: 20px;
  cursor: pointer;
  color: $c_accent;
}

.body {
  padding: 0 19px 20px;
  width: 100%;
  padding-bottom: 20px;
}

.link {
  display: block;
  width: 100%;
  padding-top: 11px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid $c_stroke;
  text-align: center;
  text-decoration: none !important;

  &:hover {
    background-color: $c_blueLight;
  }
}

.drop {
  position: relative;
  margin-left: auto;
}

.dropHeader {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}
.dropHeaderPrefix {
  color: $c_darkgray;
  margin-right: 5px;
}
.dropHeaderPrefixIcon {
  font-size: 14px;
  margin-right: 5px;
  margin-top: -2px;
  color: $c_darkgray;
}

.dropHeaderArrow {
  color: $c_darkgray;
  font-size: 20px;
  transform: scaleY(-1);
}
