// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;

  @include media-breakpoint-down(lg) {
    bottom: 0;
    height: auto;
    max-width: 100%;
    margin-left: 0;
    min-height: 100%;
  }

  &::before {
    @include media-breakpoint-down(lg) {
      content: '';
      display: block;
      width: 100%;
      min-height: 160px;
      flex-grow: 1;
    }
  }
}

.content {
  background: $c_bg;
  height: 100%;

  @include media-breakpoint-down(lg) {
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:after {
    @include media-breakpoint-down(lg) {
      content: '';
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 3px;
      background-color: $c_white;
      width: 60px;
      height: 6px;
    }
  }
}

.children {
  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}

.header,
.footer {
  flex-shrink: 0;
  width: 100%;
}

.body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.inner {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 100%;
}
