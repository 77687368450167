// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.select {
  padding-right: 50px;
}

.arrowIcon {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  font-size: 30px;
  transform: rotate(180deg);
  color: $c_darkgray;
}
