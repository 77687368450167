// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.root {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(xxl) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
  }
}

.left {
  width: 365px;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xxl) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.title {
  font-size: 1rem;
  color: $c_dark;
  font-weight: 500;
  line-height: 30px;
  width: 100%;
}

.desc {
  padding-top: 0px;
  font-size: 12px;
  font-weight: 400;
}

.content {
  width: 100%;
}

.split {
  @include media-breakpoint-down(xxl) {
    margin-bottom: 20px;
  }
}
