// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.col {
  margin: 10px 0;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.plate {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $c_bg;
  border: 1px solid $c_stroke;
  border-radius: 7px;
  padding: 20px 10px 8px;
}

.icon {
  font-size: 30px;
  color: $c_accent;
  margin-bottom: 9px;
}

.count {
  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  color: $c_accent;
}

.description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 1px;
}
