// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.navigation {
  font-size: 14px;
  line-height: 20px;
  padding: 0.625rem;
  border-bottom: 1px solid $c_stroke;
  text-align: center;
  margin-bottom: -1px;
}

.helpText {
  font-size: 14px;
  margin-top: -8px;
  line-height: 20px;
  margin-bottom: 17px;
  cursor: default;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 2px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.groupText {
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.resetLink {
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}

.referer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 9px;
  border-bottom: 1px solid $c_stroke;
}

.refererAvatar {
  font-size: 40px;
  margin-left: -20px;
  margin-right: 10px;
}

.refererDescription {
  font-size: 16px;
  line-height: 40px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 19px;
}

.message {
  display: flex;
  align-items: center;
}

.messageIcon {
  font-size: 30px;
  margin-right: 10px;
  color: $c_orange;
}

.messageTxt {
  font-size: 16px;
  line-height: 40px;
}

.preview {
  margin-top: 26px;
}
