// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 280px;
  height: 100%;
  z-index: 102;

  @include media-breakpoint-down(lg) {
    right: 0;
    width: 100%;
    height: 60px;
  }
}

.projects {
  display: flex;
  flex-direction: column;
  width: $toolsSize;
  background-color: $c_accent;
  color: $c_white;

  @include media-breakpoint-down(lg) {
    width: 100%;
    flex-direction: row;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $toolsSize;
  height: $toolsSize;
  background-color: $c_toryBlue;
  color: $c_perano;
  font-size: 1.875rem;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    color: $c_white;
  }
}

.inner {
  padding-top: 26px;
  padding-bottom: 24px;
  flex-grow: 1;

  @include media-breakpoint-down(lg) {
    padding: 6px 0;
    margin-left: 16px;
  }
}
