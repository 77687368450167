// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.help {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: $c_accent;
}

.helpTxt {
  margin-left: 10px;
}

@include media-breakpoint-down(sm) {
  .messageIcon {
    font-size: 24px;
  }

  .messageTxt {
    font-size: 14px;
    line-height: 30px;
  }
}
