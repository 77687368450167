// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.group {
  position: relative;
  display: flex;
}

.controlGroup {
  flex-grow: 1;
  margin-bottom: 0;
}

.controlField {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
  border-right-width: 0;
}

.action {
  margin-top: 10px;
  padding: 0 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 100px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid $c_darkgray;
  border-radius: 0px 8px 8px 0px;
  background-color: $c_white;
  color: $c_accent;
}

.actionIcon {
  flex-shrink: 0;
  min-width: 0;
  font-size: 20px;
  margin-right: 10px;
}

.actionDesc {
  flex-shrink: 0;
  min-width: 0;
}
