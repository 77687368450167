// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

$itemHeight: 100px;
$posterHeight: 80px;
$posterWidth: 120px;

.link {
  &:hover {
    text-decoration: none;
  }
}

.closed {
  pointer-events: none;
}

.item {
  display: flex;
  align-items: center;
  height: $itemHeight;
  padding-left: 33px;
  padding-right: 30px;
  border: none;
  width: 100%;
}

.number {
  margin-right: 10px;
  font-size: 14px;
}

.approve {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c_white;
  width: 20px;
  height: 20px;
  background-color: $c_green;

  svg {
    width: 100%;
    height: 100%;
  }
}

.title {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.poster {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: $posterHeight;
  min-width: $posterWidth;
  padding: 10px;
  background-size: cover;
}

.posterBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.duration {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c_dark;
  min-width: 45px;
  max-height: 20px;
  color: $c_white;
  font-size: 14px;
  border-radius: 2px;
}

.closedPoster {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $posterHeight;
  min-width: $posterWidth;
  // cursor: not-allowed;
}

.closedText {
  position: relative;
  z-index: 2;
  font-size: 10px;
  color: $c_white;
  padding: 0 10px;
}

.greyBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $c_darkgray;
  opacity: 0.9;
}

.lock {
  width: 30px;
  height: 30px;
  z-index: 2;
  color: $c_white;
}
