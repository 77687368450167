// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $c_white;
  padding: 10px 12.5px;
  height: 90px;
  border-top: 1px solid #eff1f3;
  overflow: auto;
  display: flex;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  color: inherit;
  min-width: 65px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  flex-grow: 1;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 0;

  &.isSelected {
    color: $c_accent;
  }

  &:not(:last-child) {
    // margin-right: 15px;
  }
}

.icon {
  position: relative;
  font-size: 30px;
  margin-bottom: 6px;
}

.title {
  position: relative;
  font-size: 8px;
  line-height: normal;
  text-transform: uppercase;
}
