// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.avatar {
  width: 1em;
  height: 1em;
  stroke-width: 0;
  background-color: $c_bg;

  &:not(.isLight) {
    color: $c_white;
  }

  &:not(.isSquare) {
    border-radius: 50%;
  }
}

.text {
  line-height: 1;
  font-size: 40px;
}
