// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.root {
  padding-top: 0;
  background-color: $c_white;
  border: 1px solid $c_stroke;
  border-radius: 8px;
  margin-bottom: 11px;
}

.header {
  padding: 0 9px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.left {
  display: flex;
  align-items: center;
}

.icon {
  color: $c_accent;
  padding-right: 10px;
  font-size: 20px;
}

.title {
  text-transform: uppercase;
  color: $c_darkgray;
  font-size: 12px;
}

.checkboxIcon {
  font-size: 20px;
  background-color: $c_lightgreen;
  color: $c_green;
}

.body {
  padding: 0 9px 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.desc {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 20px;
}

.user {
  font-weight: 600;
}

.time {
  margin-bottom: -4px;
  color: $c_darkgray;
  font-weight: 300;
  font-size: 13px;
}

.footer {
  text-align: center;
  border-top: 1px solid $c_stroke;
  cursor: pointer;
  &:hover {
    background-color: $c_stroke;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.action {
  margin: 0;
  padding: 7px 0;
  background: none;
  border: none;
  color: $c_accent;
  font-size: 0.875rem;
}
