// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.courseRating {
  display: flex;
  align-items: center;

  svg {
    color: $c_orange;
  }
}

.starCount {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $c_orange;
  color: $c_white;
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  width: 30px;
  height: 26px;
  margin-right: 10px;
}

.stars {
  display: flex;
  align-items: center;
}

.star {
  margin-right: 5px;
}

.small {
  max-height: 20px;
}
