// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.avatar {
  font-size: 60px;
  border-radius: 50%;
  background-color: rgba($c_accent, 0.3);
  margin-right: 10px;
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.selectLabel {
  background-color: $c_bg;
}
