// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.projects {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: calc(100vh - 150px);
  max-width: calc(100vw - 200px);
  overflow: auto;
  -ms-overflow-style: none;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
  }

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}

.outline {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  border: 2px solid $c_white;
  border-radius: 10px;
}

.project {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-bottom: 2px;

    @include media-breakpoint-down(lg) {
      margin-right: 4px;
      margin-bottom: 0;
    }
  }
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $c_dark;
  color: $c_stroke;
  border-radius: 8px;
}

.icon {
  font-size: 1.5rem;
}

.preview {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 300ms ease;
  box-shadow: 0 0 14px -10px $c_white;

  .project:not(.isSelected):hover & {
    transform: scale(1.08);
  }
}

.avatar {
  font-size: 40px;
}

.trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba($color: $c_stroke, $alpha: 0.2);
  border-radius: 7px;
  transition-property: transform, border-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  .project:not(.isSelected):hover & {
    transform: scale(1.08);
    border-color: rgba($color: $c_stroke, $alpha: 0.6);
  }
}

.plus {
  font-size: 20px;
  color: $c_white;
}
