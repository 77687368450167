// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.footer {
  position: relative;
  display: flex;
  height: 5rem;
  background: $c_bg;
  border-top: 1px solid $c_stroke;
  border-radius: 0px 0px 7px 7px;
}

.trigger,
.social {
  flex-grow: 1;
  width: calc(50% - 0.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.trigger {
  position: relative;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 10px;
  background-color: $c_accent;
  color: $c_white;
  transition: background-color 200ms ease;

  &:hover {
    background-color: darken($c_accent, 2);
  }

  &:first-child {
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-bottom-right-radius: 6px;
  }

  &:not(:last-child) {
    width: calc(50% + 0.5px);
    border-right: 1px solid $c_stroke;
  }

  &.pr20 {
    padding-right: 20px;
  }

  &.isSecond {
    background-color: $c_second;
    color: $c_dark;
    width: auto;
    flex-shrink: 0;
    width: 160px;

    &:hover {
      background-color: darken($c_second, 4);
    }
  }

  &.isFluid {
    width: 100%;
    flex-grow: 1;
  }
}

.tiggerBlade {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 100%;
  background-color: rgba(#fff, 0.05);
  filter: blur(4px);
}

.tiggerBlade2 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 5px;
  height: 100%;
  background-color: rgba(#fff, 0.05);
  filter: blur(4px);
}

.triggerIcon {
  position: relative;
  font-size: 1.25rem;
  margin-right: 0.625rem;
  transition: transform 200ms ease;

  .trigger:hover & {
    transform: scale(1.2);
  }
}

.triggerDesc {
  position: relative;
}

.divider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: $c_white;
  border: 1px solid $c_stroke;
  font-size: 0.6875rem;
  line-height: 1.25rem;
  color: $c_darkgray;

  @include media-breakpoint-down(sm) {
    font-size: 0.6rem;
    height: 2rem;
    width: 2rem;
  }
}

.social {
  padding-left: 20px;
  background-color: $c_second;
  border-bottom-right-radius: 6px;
}

.socialItem {
  cursor: pointer;
  color: $c_accent;
  transition: transform 200ms ease;

  &:not(:last-child) {
    margin-right: 1.25rem;

    @include media-breakpoint-down(sm) {
      margin-right: 1rem;
    }
  }

  &:hover {
    transform: scale(1.2);
  }
}

.socialIcon {
  display: block;
  font-size: 1.875rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}
