// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.accordion {
  border-top: 1px solid $c_stroke;
  border-bottom: 1px solid $c_stroke;
  margin-bottom: 7px;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  color: $c_accent;
  padding: 15px 30px;
}

.text {
  display: flex;
  align-items: center;
}

.materials {
  overflow: hidden;
  margin-bottom: 18px;
  margin-top: -5px;
}

.arrow {
  width: 20px;
  height: 20px;
}

.documentIcon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
