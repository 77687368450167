// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.row {
  display: flex;
  max-width: 257px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 18px;
}

.col {
  width: 50%;
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
}

.description {
  font-size: 14px;
  line-height: 20px;
  margin-top: 1px;
}
