// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.thumb {
  display: flex;
  position: relative;
  width: 1em;
  height: 1em;
  padding: 5px;
}

.union {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1em;
  color: $c_accent;
}

.preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: $c_stroke;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.placeholderIcon {
  font-size: 20px;
}

.placeholderTxt {
  font-size: 12px;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
