// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin: 0 0 10px;

  @include media-breakpoint-down(lg) {
    margin: 0;
  }
}

.isAction {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.filters {
  display: flex;
  padding: 10px 0;
  margin-bottom: 20px;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    flex-grow: 1;
  }
}

.filterBtn {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: $c_darkgray;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.filterIco {
  font-size: 14px;
}

.filterTxt {
  margin: 0 5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.filterArrow {
  transform: rotateX(180deg);
  font-size: 20px;
  transition: transform 0.3s ease;
}

.isExpanded {
  transform: rotateX(0);
}
