// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contacts {
  padding-top: 0.7rem;
}

.phone {
  margin: 0;
}

.social {
  padding-bottom: 1.9rem;
  display: flex;
  justify-content: space-between;
}

.split {
  margin-top: 20px;
  margin-bottom: 29px;
  margin-left: -1.875rem;
  width: calc(100% + 3.75rem);
  border-bottom: 1px solid $c_stroke;
}

.source {
  margin-top: -7px;
}
