// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.group {
  position: relative;
  padding-top: 10px;
  margin-bottom: 10px;
}

.label {
  position: absolute;
  padding: 0 0.3125rem;
  top: 0;
  left: 1.25rem;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.42857143;
  color: $c_darkgray;
  background-color: $c_bg;

  &.isGreen {
    color: $c_green;
  }
}

.field {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.875;
  padding: 0.5625rem 1.25rem;
  background-color: $c_bg;
  border: 1px solid $c_darkgray;
  border-radius: 0.5rem;
  transition: border-color 200ms ease;
  appearance: none;

  &:disabled {
    color: $c_darkgray;
  }

  &:focus {
    border-color: $c_dark;
    outline: 0;
  }

  &:hover {
    border-color: $c_dark;
  }

  &::placeholder {
    color: rgba($c_dark, 0.3);
  }
}

.error {
  display: flex;
  justify-content: flex-end;
  min-height: 1.25rem;
  margin-top: -10px;
  margin-bottom: -1px;
  padding-right: 1.25rem;
  font-size: 12px;
  line-height: 1.42857143;
  color: $c_red;
}

.errorMsg {
  display: block;
  overflow: hidden;
  width: 0;
  background-color: $c_white;
}

.errorMsgInner {
  display: block;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  white-space: nowrap;
}
