// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.videoBlock {
  display: flex;
  flex-direction: column;
  background-color: $c_white;
  flex-shrink: 1;

  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: zIndex('fixed');
    flex-direction: column;
  }
}

.header {
  display: none;

  @include media-breakpoint-down(lg) {
    display: flex;
    & > div {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    & > div {
      padding: 0 30px;
    }
  }
}

.video {
  width: 100%;
  height: 100%;
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  color: $c_accent;
}

$iconSize: 24px;

.backIcon,
.watchedVideoIcon {
  width: $iconSize;
  height: $iconSize;
}

.backIcon {
  rotate: -90deg;
  margin-right: 20px;
}
.watchedVideoIcon {
  rotate: 90deg;
  margin-left: 20px;
}
