// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.footer {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  position: relative;

  &:before {
    @include media-breakpoint-down(lg) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $c_stroke;
    }
  }
}

.container {
  display: flex;
  flex-grow: 1;

  @include media-breakpoint-down(lg) {
    max-width: 660px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 20px 20px;
  width: 100%;
  border: 0;
  border-top: 1px solid $c_stroke;
  font-size: 14px;
  line-height: 20px;
  color: $c_accent;
  background-color: transparent;

  @include media-breakpoint-down(lg) {
    border-top: none;
    padding: 20px 0;
  }

  &:hover {
    background-color: $c_white;
  }
  &.isPrimary {
    flex-shrink: 2;
    color: $c_dark;
    border-right: 1px solid $c_stroke;
  }
}

.actionIcon {
  font-size: 20px;
  margin-right: 10px;
}
