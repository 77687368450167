// 1. Functions for make more powerful mixin's
@import '../../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../../styles/abstracts/mixins';

.root {
  position: relative;
  padding-top: 10px;
  margin-bottom: 19px;
}

.selector {
  display: flex;
}

.radio {
  display: none;
}

.circle {
  margin-top: 8px;
  width: 30px;
  height: 30px;
  background-color: $c_green;
  border-radius: 50%;
  border: 1px solid $c_stroke;
  transition: all 0.2s;
  margin-right: 1.25rem;
  cursor: pointer;
}

.active {
  position: relative;
  margin-top: 6px;
  margin-right: 1.125rem;
  width: 36px;
  height: 36px;
}
