// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: $c_darkgray;
  color: $c_white;
  align-items: center;

  @include media-breakpoint-down(lg) {
    padding: 0 20px;
  }
}

.inner {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.body {
  background: $c_white;
  border: 1px solid $c_stroke;
  border-radius: 7px;
  color: initial;
}
