// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.wrapper {
  position: relative;
  display: block;
  width: 1em;
  height: 1em;
  font-size: 100px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
  visibility: hidden;
}
