// 1. Functions for make more powerful mixin's
@import '../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../styles/abstracts/mixins';

.logo {
  display: flex;
  align-items: center;
  color: $c_white;
}

.icon {
  font-size: 3.75rem;
}

.description {
  font-weight: 500;
  margin-left: 1.25rem;
  font-size: 1rem;
}
