// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.col {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;

  &.isFluid {
    width: 100%;
  }
}

.graph {
  align-items: center;
}

.graphPreview {
  position: relative;
}

.graphCircle {
  width: 100%;

  @include media-breakpoint-down(lg) {
    height: auto;
    max-height: 220px;
  }
}

.graphPercent {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.graphDescription {
  font-size: 12px;
}

.graphLabel {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.graphLabelColor {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 7px;
  background: $c_stroke;
}

.graphLabelTitle {
  font-size: 14px;
  line-height: 20px;
}

.statistic {
  background: $c_bg;
  border: 1px solid $c_stroke;
  border-radius: 7px;
  padding: 19px 19px 8px;
  text-align: center;
}

.statisticTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: $c_accent;
}

.statisticDesc {
  font-size: 14px;
  line-height: 30px;
  margin-top: -3px;
}
