// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 2px;
  font-size: 20px;
  font-weight: 500;
  color: $c_dark;
}

.updated {
  padding-top: 7px;
  font-size: 14px;
  color: $c_darkgray;
  border-radius: 7px;
}

.image {
  padding-top: 27px;
  padding-bottom: 31px;
  width: 100%;
}
.article {
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  color: $c_dark;
}
