// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 9px;
  border-bottom: 1px solid $c_stroke;
  background-color: $c_white;
  position: relative;

  @include media-breakpoint-down(lg) {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0;
  }
}

.container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    max-width: 660px;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 40px;
  margin: 0;
  font-weight: 500;
}

.close {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  margin-right: -10px;
  border-width: 0;
  background-color: transparent;

  @include media-breakpoint-down(lg) {
    color: $c_darkgray;
  }
}

.closeIcon {
  font-size: 20px;
  margin-right: 10px;
}
