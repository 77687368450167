// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.button {
  padding: 0;
  padding-top: 7px;
  background: none;
  border: none;
  color: $c_accent;
  font-size: 0.875rem;

  @include media-breakpoint-down(lg) {
    width: 100%;
    position: relative;
    height: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $c_stroke;
    height: 1px;
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    @include media-breakpoint-down(md) {
      left: -50vw;
      right: -50vw;
    }
  }
}

.icon {
  font-size: 1.2rem;
  color: $c_accent;
  margin-right: 11px;
}
