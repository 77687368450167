// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.inviter {
  display: flex;
  padding-bottom: 8px;
}

.preview {
  padding-right: 20px;
  flex-shrink: 0;
  min-width: 0;
  width: 90px;
}

.avatar {
  width: 70px;
  height: auto;
  border-radius: 50%;
}

.caption {
  padding-top: 10px;
  width: calc(100% - 90px);
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.description {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $c_darkgray;
}

.divider {
  margin: 0 5px;
}

.contact {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
}

.contactLink {
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  min-width: 0;
  text-overflow: ellipsis;
}

.contactSocials {
  flex-shrink: 0;
  min-width: 0;
  display: flex;
  margin-left: 7px;
}

.contactSocialsItem:not(:last-child) {
  margin-right: 10px;
}

.contactSocialsIcon {
  display: block;
  font-size: 20px;
}
