// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.tip {
  display: flex;
  width: 1em;
  height: 1em;
  font-size: 1.25rem;
  margin-left: 0.625rem;
  cursor: pointer;
  color: $c_accent;
}
