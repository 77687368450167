// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.courseInfo {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.wrapper {
  padding: 0 30px;
}

.header {
  @include media-breakpoint-down(lg) {
    & > div {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
  @include media-breakpoint-down(md) {
    & > div {
      padding: 0 30px;
    }
  }
}

.title {
  margin-top: 12px;
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 24px;
}

.body {
  height: 100%;
  background: #f8faf8;
  padding-top: 12px;
}
