// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.dropdown {
  position: relative;
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0px 30px;
  margin: 0;
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    padding: 0 15px;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border: 1px solid $c_stroke;
  border-radius: 50%;
  overflow: hidden;
}

.title {
  font-size: 0.875rem;
  line-height: 1.875rem;
  margin-left: 0.625rem;
}

.linkIcon {
  transform: scaleY(-1);
  font-size: 1.25rem;
  margin-left: 11px;

  &.isExpanded {
    transform: none;
  }
}

.body {
  position: absolute;
  top: 100%;
  left: -1px;
  right: 0;
  background-color: $c_white;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 1px solid $c_stroke;
  border-top: 0;

  @include media-breakpoint-down(lg) {
    left: auto;
    width: 100vw;
    display: flex;
    padding: 0;
  }
}

.action {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 30px;
  color: $c_accent;

  @include media-breakpoint-down(lg) {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &:hover {
    background-color: $c_stroke;
  }
}

.actionIcon {
  font-size: 1.25rem;
  margin-right: 0.3125rem;
}

.actionTtl {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
