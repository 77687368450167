// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.socials {
  height: inherit;
  display: flex;
  align-items: center;
  border-left: 1px solid $c_stroke;
  padding-left: 18.5px;
  padding-right: 20.5px;

  @include media-breakpoint-down(xl) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
    border-left: none;
  }
}

.soc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e7e7e7;

  &.isTelegram {
    color: getSocColor('telegram');
  }
  &.isViber {
    color: getSocColor('viber');
  }
  &.isWhatsapp {
    color: getSocColor('whatsapp');
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.socIcon {
  font-size: 30px;
}
