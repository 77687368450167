// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.spinnerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
}

.spinner {
  width: 203px;
  height: 203px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

@keyframes spinnerContent {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContent {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;

  div {
    box-sizing: border-box !important;
  }

  > div {
    position: absolute;
    width: 108px;
    height: 108px;
    top: 48px;
    left: 48px;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: lighten(red, 25%) transparent lighten(red, 25%) transparent;
    animation: spinnerContent 1.2s linear infinite;
  }

  > div:nth-child(2),
  > div:nth-child(4) {
    width: 92px;
    height: 92px;
    top: 56px;
    left: 56px;
    animation: spinnerContent 1.2s linear infinite reverse;
  }

  > div:nth-child(2) {
    border-color: transparent red transparent red;
  }

  > div:nth-child(3) {
    border-color: transparent;
  }

  > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }

  > div:nth-child(3) div:before,
  > div:nth-child(3) div:after {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: -6px;
    left: 44px;
    background: lighten(red, 25%);
    border-radius: 50%;
    box-shadow: 0 102px 0 0 lighten(red, 25%);
  }

  > div:nth-child(3) div:after {
    left: -6px;
    top: 44px;
    box-shadow: 102px 0 0 0 lighten(red, 25%);
  }

  > div:nth-child(4) {
    border-color: transparent;
  }

  > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }

  > div:nth-child(4) div:before,
  > div:nth-child(4) div:after {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: -6px;
    left: 36px;
    background: red;
    border-radius: 50%;
    box-shadow: 0 86px 0 0 red;
  }

  > div:nth-child(4) div:after {
    left: -6px;
    top: 36px;
    box-shadow: 86px 0 0 0 red;
  }
}

.fullPage {
  width: 100vw;
  height: 100vh;
}

.absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forButton {
  position: absolute;

  .spinner {
    width: 50px;
    height: 50px;
    display: inline-block;
  }

  .spinnerContent {
    > div {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 9px;
      left: 11px;
      border-width: 3px;
      border-color: #fff transparent #fff transparent;
    }

    > div:nth-child(2),
    > div:nth-child(4) {
      width: 20px;
      height: 20px;
      top: 15px;
      left: 17px;
    }

    > div:nth-child(4) div:before,
    > div:nth-child(4) div:after,
    > div:nth-child(3) div:before,
    > div:nth-child(3) div:after {
      display: none;
    }
  }
}

.colorBlue .spinnerContent {
  > div {
    border-color: lighten(red, 25%) transparent lighten(red, 25%) transparent;
  }

  > div:nth-child(2) {
    border-color: transparent red transparent red;
  }

  > div:nth-child(3) {
    border-color: transparent;
  }

  > div:nth-child(3) div:before,
  > div:nth-child(3) div:after {
    background: lighten(red, 25%);
    box-shadow: 0 102px 0 0 lighten(red, 25%);
  }

  > div:nth-child(3) div:after {
    box-shadow: 102px 0 0 0 lighten(red, 25%);
  }

  > div:nth-child(4) {
    border-color: transparent;
  }

  > div:nth-child(4) div:before,
  > div:nth-child(4) div:after {
    background: red;
    box-shadow: 0 86px 0 0 red;
  }

  > div:nth-child(4) div:after {
    box-shadow: 86px 0 0 0 red;
  }
}
