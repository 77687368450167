// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.courseCard {
  display: flex;
  flex-direction: column;
  height: 240px;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none !important;
  transition-timing-function: ease;
  transition-property: transform;
  transition-duration: 200ms;

  &:hover {
    transform: scale(1.02);
  }
}

.innerFirst,
.innerSecond {
  height: 50%;
}

.innerFirst {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.innerSecond {
  background: $c_white;
  border: 1px solid $c_stroke;
}

.title {
  color: $c_dark;
  padding: 10px;
  line-height: 24px;
  margin-bottom: 0;
}

.titleInner {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.topBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.progressAndBadge {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.authorDateBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid $c_stroke;
}

.author {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.authorImage {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #eff1f3;
  margin-right: 10px;
}

.authorName {
  font-size: 14px;
  line-height: 18px;
}
