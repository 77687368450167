// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c_accent;
  border-radius: 2px;
  min-width: 90px;
  height: 30px;
  padding: 0 14.5px;
  vertical-align: center;
  margin-bottom: 0;
  color: $c_white;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
}
