// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.root {
  padding: 0 0px 0 19px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $c_white;
  border: 1px solid $c_stroke;
  border-radius: 7px;

  @include media-breakpoint-down(xl) {
    height: auto;
    align-items: stretch;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 10px 20px;
  }
}

.title {
  width: 273px;
  display: flex;
  align-items: center;
  height: inherit;
  border-right: 1px solid $c_stroke;

  @include media-breakpoint-down(xxl) {
    width: 200px;
    font-size: 14px;
  }

  @include media-breakpoint-down(xl) {
    width: 240px;
  }

  @include media-breakpoint-down(lg) {
    max-width: calc(100% - 65px);
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    border: none;
  }
}

.head {
  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.middle {
  padding: 0 17px 0 19px;
  display: flex;
  align-items: center;
  width: 100%;

  @include media-breakpoint-down(xl) {
    flex-direction: column-reverse;
    padding: 10px 20px;
  }

  @include media-breakpoint-down(lg) {
    padding: 10px 0;
  }
}

.group {
  position: relative;
  display: flex;
  align-items: center;
}

.controlGroup {
  width: 200px;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.controlField {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
  border-right-width: 0;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    padding: 12px 0 12px 15px;
    line-height: normal;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.form {
  @include media-breakpoint-down(xl) {
    width: 100%;
  }
}

.actionCopy {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid $c_darkgray;
  border-radius: 0px 8px 8px 0px;
  background-color: $c_white;
  color: $c_accent;

  @include media-breakpoint-down(xxl) {
    width: 90px;
  }

  @include media-breakpoint-down(xl) {
    height: 42.5px;
  }

  @include media-breakpoint-down(lg) {
    height: 45px;
  }
}

.actionIcon {
  font-size: 20px;
  margin-right: 10px;
}

.sales {
  margin-left: 65px;
}

.stats {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;

  @include media-breakpoint-down(xxxl) {
    justify-content: space-around;
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.clicks,
.sales {
  font-size: 12px;
  color: $c_darkgray;
  text-transform: uppercase;
}

.date {
  margin-left: 65px;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 500;
  background-color: $c_stroke;
  color: $c_darkgray;
  border-radius: 2px;

  @include media-breakpoint-down(xl) {
    background: transparent;
  }
}

.tools {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $c_stroke;
  min-width: 59px;

  @include media-breakpoint-down(lg) {
    border: none;
    min-width: auto;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: $c_darkgray;

  .icon {
    font-size: 1.3rem;
  }
}

.sales,
.date {
  @include media-breakpoint-down(xxxl) {
    margin-left: 10%;
  }

  @include media-breakpoint-down(xxl) {
    margin-left: 7%;
  }

  @include media-breakpoint-down(xl) {
    margin-left: 6%;
  }
}

.sales,
.date,
.clicks {
  @include media-breakpoint-down(xl) {
    line-height: normal;
  }

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}
