// 1. Functions for make more powerful mixin's
@import '../../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../../styles/abstracts/mixins';

.progressBar {
  width: 233px;
  max-width: 233px;
  background: $c_stroke;
  border: 3px;
  border-radius: 3px;
  margin-right: 10px;
}

.bar {
  height: 6px;
  border-radius: 3px;
  background-color: $c_accent;
}
