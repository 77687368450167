// 1. Functions for make more powerful mixin's
@import '../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../styles/abstracts/mixins';

.coursePage {
  display: grid;
  grid-template-columns: 1fr 400px;
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  z-index: zIndex('modal');

  @include media-breakpoint-down(lg) {
    display: flex;
  }
}
