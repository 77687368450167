// 1. Functions for make more powerful mixin's
@import '../../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../../styles/abstracts/mixins';

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px -1px;
}

.col {
  margin: 10px 0;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.soc {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.icon {
  font-size: 40px;
}

.title {
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
}
