// 1. Functions for make more powerful mixin's
@import '../../../../styles/abstracts/functions';

// 2. Default variables that will be used in application
@import '../../../../styles/abstracts/variables';

// 3. Mixin's for build more flexible styles
@import '../../../../styles/abstracts/mixins';

.body {
  min-height: 270px;
  padding: 1.25rem 2.5rem;

  @include media-breakpoint-down(sm) {
    padding: 1.25rem;
  }
}
